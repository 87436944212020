
import { CreateElement, RenderContext, VNode } from 'vue';

export default {
  name: 'VfContainer',
  functional: true,
  props: {
    fullHeight: Boolean,
    nested: Boolean,
    fullWidth: Boolean,
    backgroundColor: String,
    hasPlaceholder: Boolean,
  },
  render(
    h: CreateElement,
    { props, listeners, data, slots }: RenderContext
  ): VNode {
    return h(
      'div',
      {
        ...data,
        class: [
          'container',
          data.class,
          {
            'full-width': props.fullWidth,
            'full-height': props.fullHeight,
            nested: props.nested,
          },
        ],
        style: {
          ...(data.style,
          props.backgroundColor && {
            backgroundColor: props.backgroundColor,
          }),
        },
        on: listeners,
      },
      slots().default
    );
  },
};
