// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/noodle.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vf-divider[data-v-6dd2ed36]{display:var(--divider-display,block);margin:var(--divider-margin);max-width:var(--divider-max-width);width:var(--divider-width);border:var(--divider-border,var(--divider-border-style,solid) var(--divider-border-color,var(--c-text-disabled)));border-width:var(--divider-border-width,1px 0 0 0)}.vf-divider--dashed[data-v-6dd2ed36]{--divider-border-style:dashed}.vf-divider--dotted[data-v-6dd2ed36]{--divider-border-style:dotted}.vf-divider--grey[data-v-6dd2ed36]{border-color:var(--c-gray-40,#9a9a9a)}.vf-divider--noodle[data-v-6dd2ed36]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") repeat 50%;background-size:auto 100%;border:none;height:.188rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
