var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"tag",class:{
    'tag--transparent': _vm.isTransparent,
    'tag--selected': _vm.isSelected,
  },on:{"click":function($event){return _vm.$emit('click')}}},[_c('VfText',{class:{
      'tag__label--redesign-core': _vm.isCoreRedesignEnabled,
    },attrs:{"font-sizes":['sm']}},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]),_vm._v(" "),(_vm.hasDefaultSlot)?_c('div',{class:{
      tag__content: !_vm.isFullLineSelect,
      tag__spacer: _vm.isFullLineSelect,
    }},[_vm._t("default")],2):_vm._e(),_vm._v(" "),_c('VfText',{staticClass:"tag__counter",class:{
      tag__counter: !_vm.isFullLineSelect,
      tag__metric: _vm.isFullLineSelect,
      'tag__counter--redesign': _vm.isCoreRedesignEnabled,
    },attrs:{"font-sizes":['sm']}},[_vm._v("\n    "+_vm._s(_vm.counter)+"\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }