



































import { defineComponent, onUnmounted, ref, watch } from '@vue/composition-api';
import { focusTrap } from '@vf/shared/src/utils/directives';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import useRootInstance from '@vf/theme/shared/useRootInstance';
import { isClient } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'VfPanel',
  directives: { focusTrap },
  props: {
    visible: Boolean,
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { root } = useRootInstance();

    const keydownHandler = (event) => {
      if (
        event.key === 'Escape' ||
        event.key === 'Esc' ||
        event.keyCode === 27
      ) {
        emit('close');
      }
    };

    let swipeY = 0;
    const swipeStart = (event) => {
      const touch = event.changedTouches[0];
      swipeY = touch.screenY;
    };
    const swipeEnd = (event) => {
      const touch = event.changedTouches[0];
      if (touch.screenY > swipeY) emit('close');
    };

    const content = ref();
    const isLockFired = ref(false);

    const clearScrollLocks = () => {
      if (!isClient || !isLockFired.value) return;
      root.$nextTick(() => {
        clearAllBodyScrollLocks();
        isLockFired.value = false;
      });
      // Fix body scroll for iOS for 12 version and older
      if (/iP(ad|hone)/.test(navigator.userAgent)) {
        document.body.classList.remove('block-scroll');
      }
      document.removeEventListener('keydown', keydownHandler);
    };

    watch(
      props,
      ({ visible }) => {
        if (!isClient) return;
        if (visible) {
          root.$nextTick(() => {
            // Specifically, the target element is the one we would like to allow scroll on (NOT a parent of that element).
            disableBodyScroll(content.value);
            // Fix body scroll for iOS for 12 version and older
            if (/iP(ad|hone)/.test(navigator.userAgent)) {
              document.body.classList.add('block-scroll');
            }
            isLockFired.value = true;
          });
          document.addEventListener('keydown', keydownHandler);
        } else {
          clearScrollLocks();
        }
      },
      { immediate: true }
    );

    onUnmounted(() => {
      clearScrollLocks();
    });

    return { content, swipeStart, swipeEnd };
  },
});
