




























import { defineComponent } from '@vue/composition-api';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'VfReviewSubmittedBy',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    date: {
      type: String,
      default: '',
    },
    buyer: {
      type: String,
      default: '',
    },
    from: {
      type: String,
      default: '',
    },
    isVerifiedBuyer: {
      type: Boolean,
      default: false,
    },
    submittedLabel: {
      type: String,
      default: '',
    },
    submittedByLabel: {
      type: String,
      default: '',
    },
    fromLabel: {
      type: String,
      default: '',
    },
    verifiedBuyerLabel: {
      type: String,
      default: '',
    },
    disclosureLabel: {
      type: String,
      default: '',
    },
  },
});
