




































import {
  defineComponent,
  inject,
  computed,
  ref,
  PropType,
} from '@vue/composition-api';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { ProductReviewFaceoff } from 'packages/api-contract/src/entities';

const TEXT_CAP_CHAR_LIMIT = 235;
const ELLIPSIS = '...';

export default defineComponent({
  name: 'VfMostLikedReview',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    heading: {
      type: String,
      default: '',
    },
    readCompleteText: {
      type: String,
      default: '',
    },
    readLessText: {
      type: String,
      default: '',
    },
    review: {
      type: Object as PropType<ProductReviewFaceoff>,
      default: (): ProductReviewFaceoff => ({
        headline: '',
        rating: 0,
        comments: '',
      }),
    },
  },
  setup(props) {
    const isExpanded = ref(false);
    const isExpandable = computed(
      () => props.review.comments?.length >= TEXT_CAP_CHAR_LIMIT
    );
    const transformedText = computed(
      () => props.review.comments.slice(0, TEXT_CAP_CHAR_LIMIT) + ELLIPSIS
    );
    const reviewText = computed(() =>
      isExpandable.value && !isExpanded.value
        ? transformedText.value
        : props.review.comments
    );
    const toggleIsExpanded = () => {
      isExpanded.value = !isExpanded.value;
    };

    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    return {
      isCoreRedesignEnabled,
      isExpanded,
      isExpandable,
      toggleIsExpanded,
      reviewText,
    };
  },
});
