//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { defineComponent, inject } from '@vue/composition-api';
export default defineComponent({
  name: 'VfHelpful',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    thumbUpCount: {
      type: Number,
      default: 0,
    },
    thumbDownCount: {
      type: Number,
      default: 0,
    },
    isUpSelected: {
      type: Boolean,
      default: false,
    },
    isDownSelected: {
      type: Boolean,
      default: false,
    },
    ariaLabelHelpful: {
      type: String,
      default: '$helpful$',
    },
    ariaLabelUnhelpful: {
      type: String,
      default: '$unhelpful$',
    },
  },
  setup() {
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    return {
      isCoreRedesignEnabled,
    };
  },
});
