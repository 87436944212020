























































































































import {
  Ref,
  ref,
  watch,
  defineComponent,
  onMounted,
} from '@vue/composition-api';

import { useFindInStore, useI18n, useProduct } from '@vf/composables';
import ProductFindInStoreList from '@vf/theme/components/findInStore/ProductFindInStoreList.vue';
import useRootInstance from '@/shared/useRootInstance';
import { scrollIntoView } from '@vf/shared/src/utils/helpers';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

type DistanceOption = {
  label: string;
  value: number;
};

export default defineComponent({
  name: 'ProductFindInStore',
  components: {
    ProductFindInStoreList,
  },
  layout: 'emptyNative',
  transition: 'fade',
  setup() {
    const { root } = useRootInstance();
    const { isFindInStorePageEnabled } = useFeatureFlagsStore();
    const postalCode: Ref<string> = ref('');
    const pageCanLoad: Ref<boolean> = ref(true);
    const postalCodeEmpty: Ref<boolean> = ref(false);
    const distanceValue: Ref<number> = ref(
      root.$themeConfig.findInStore.defaultDistance
    );

    const SELECTORS = '.find-in-store__form';
    const {
      getStoresByPostalCode,
      getStoresByGeo,
      emptyResponseError,
      resetData,
    } = useFindInStore(root);
    const { getStaticTranslation } = useI18n(root);
    const { configure, getProductDetails, product } = useProduct(
      root,
      'product'
    );
    const pageTranslations = getStaticTranslation('pagesFindInStore_Id');
    const unitOfMeasureLabel =
      root.$themeSettings.pagesFindInStore_Id.unitOfMeasure === 'km'
        ? pageTranslations.km
        : pageTranslations.miles;
    const distances = root.$themeSettings.pagesFindInStore_Id.distances.reduce(
      (distanceOptions: DistanceOption[], distance: number) => {
        return distanceOptions.concat({
          label: `${distance} ${unitOfMeasureLabel}`,
          value: distance,
        });
      },
      [] as DistanceOption[]
    );

    const productId: string = root.$route.params.id.toUpperCase();
    const variantId: string | undefined = root.$route.query
      ?.variantId as string;
    const colorCode: string | undefined = root.$route.query
      ?.colorCode as string;

    if (!isFindInStorePageEnabled || !productId || !variantId || !colorCode) {
      root.$log.warn(
        `[@theme/pages/find-in-store/_id.vue::setup] Page cannot load due to lack of required data. isFindInStorePageEnabled: <${isFindInStorePageEnabled}>, id: <${productId}>, variantId: <${variantId}>, colorCode: <${colorCode}>`
      );
      pageCanLoad.value = false;
    }

    const searchByPC = async () => {
      if (postalCode.value === '') {
        postalCodeEmpty.value = true;
        resetData();
        return;
      }
      await getStoresByPostalCode(
        postalCode.value,
        distanceValue.value.toString(),
        root.$themeSettings.pagesFindInStore_Id.unitOfMeasure.toLowerCase(),
        variantId
      );

      scrollIntoView(SELECTORS);
    };

    const getCurrentPosition = () => {
      if (!navigator.geolocation) return Promise.resolve(null);
      return new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
    };

    const searchByGeo = async () => {
      const position = (await getCurrentPosition()) as any;
      if (!position) return;
      await getStoresByGeo(
        position.coords.latitude,
        position.coords.longitude,
        distanceValue.value.toString(),
        root.$themeSettings.pagesFindInStore_Id.unitOfMeasure.toLowerCase(),
        variantId
      );

      scrollIntoView(SELECTORS);
    };

    const setDistance = (val: number) => {
      distanceValue.value = val;
    };

    watch(postalCode, () => {
      if (postalCodeEmpty.value && postalCode.value !== '')
        postalCodeEmpty.value = false;
    });

    const reloadPage = () => {
      root.$router.go(0);
    };

    onMounted(async () => {
      try {
        if (!pageCanLoad.value) return;
        await getProductDetails(productId, {
          isBackgroundRequest: false,
          loadImages: true,
          saveVariation: true,
          saveOnlyColor: true,
          configuredColor: colorCode,
        });
        configure({ color: colorCode });
      } catch (err) {
        root.$log.error(
          `@theme/pages/find-in-store/_id.vue::onMounted Error: ${err.message}`,
          { err }
        );
      }
    });

    return {
      postalCode,
      distances,
      distanceValue,
      searchByPC,
      setDistance,
      postalCodeEmpty,
      searchByGeo,
      emptyResponseError,
      product,
      SELECTORS,
      pageCanLoad,
      reloadPage,
    };
  },
});
