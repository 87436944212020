import { Ref, ref } from '@vue/composition-api';

import { ComponentInstance } from '../types';
import { useI18n } from '../useI18n';
import { ROUTES } from '../utils/routes';
import { apiClientFactory } from '@vf/api-client';
import { prepareQuery } from '../utils/query';

import { setWindowLocationHref } from '@vf/shared/src/utils/helpers';

type UseReferral = {
  isLoading: Ref<boolean>;
  getAffiliateCookies: () => Promise<void>;
};

const useReferral = (instance: ComponentInstance): UseReferral => {
  const { localePath } = useI18n(instance);
  const { getAffiliateCookies: getAffiliateCookiesAPI } = apiClientFactory(
    instance
  );

  const isLoading = ref(false);

  const getAffiliateCookies = async () => {
    const { siteId, source, url } = instance.$route.query;
    const preparedQuery = prepareQuery({ siteId, source, url });
    try {
      isLoading.value = true;
      await getAffiliateCookiesAPI(preparedQuery);
      const domainFromURL = new URL(url as string).hostname;
      const domainURL = window.location.hostname;
      if (domainURL === domainFromURL) {
        setWindowLocationHref(url as string);
      } else {
        instance.$router.push(localePath(ROUTES.HOME()));
      }
    } catch (e) {
      console.error('Could not get affiliate cookies', e);
      instance.$router.push(localePath(ROUTES.HOME()));
    } finally {
      isLoading.value = false;
    }
  };

  return {
    getAffiliateCookies,
    isLoading,
  };
};

export default useReferral;
