
import { CreateElement, RenderContext, VNode } from 'vue';

export default {
  name: 'VfRow',
  functional: true,
  props: {
    fullHeight: Boolean,
    nested: Boolean,
    noMargin: Boolean,
    contentPosition: {
      type: Object,
      default: () => ({
        small: 'left',
        medium: 'left',
        large: 'left',
      }),
    },
  },
  render(
    h: CreateElement,
    { props, listeners, data, slots }: RenderContext
  ): VNode {
    return h(
      'div',
      {
        ...data,
        class: [
          'row',
          data.class,
          {
            'full-height': props.fullHeight,
            nested: props.nested,
            'no-margin': props.noMargin,
            ...(props.contentPosition.small && {
              [`align-${props.contentPosition.small}-sm`]: true,
            }),
            ...(props.contentPosition.medium && {
              [`align-${props.contentPosition.medium}-md`]: true,
            }),
            ...(props.contentPosition.large && {
              [`align-${props.contentPosition.large}-lg`]: true,
            }),
          },
        ],
        on: listeners,
      },
      slots().default
    );
  },
};
