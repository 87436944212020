import { render, staticRenderFns } from "./Molecule.ReviewCategories.vue?vue&type=template&id=2490c770&scoped=true&"
import script from "./Molecule.ReviewCategories.vue?vue&type=script&lang=ts&"
export * from "./Molecule.ReviewCategories.vue?vue&type=script&lang=ts&"
import style0 from "./Molecule.ReviewCategories.vue?vue&type=style&index=0&id=2490c770&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2490c770",
  null
  
)

export default component.exports