var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"helpful"},[_c('ThemeLink',{attrs:{"legacy":false,"aria-label":_vm.ariaLabelHelpful},on:{"click":function($event){_vm.isUpSelected || _vm.isDownSelected ? undefined : _vm.$emit('helpful-thumb-up')}}},[_c('VfIcon',{class:[
        { 'helpful__thumb-up__selected': _vm.isUpSelected },
        { 'helpful__thumb-up__clickable': !(_vm.isUpSelected || _vm.isDownSelected) },
        'helpful__thumb-up' ],attrs:{"icon":"thumbsUp","size":"xs","aria-hidden":"true"}}),_vm._v(" "),_c('VfText',{class:[
        { 'helpful__thumb-up-count__selected': _vm.isUpSelected },
        { 'helpful__thumb-up-count--redesign': _vm.isCoreRedesignEnabled },
        'helpful__thumb-up-count' ],attrs:{"aria-hidden":"true"}},[_vm._v("\n      "+_vm._s(_vm.thumbUpCount)+"\n    ")])],1),_vm._v(" "),_c('ThemeLink',{attrs:{"legacy":false,"aria-label":_vm.ariaLabelUnhelpful},on:{"click":function($event){_vm.isUpSelected || _vm.isDownSelected ? undefined : _vm.$emit('helpful-thumb-down')}}},[_c('VfIcon',{class:[
        { 'helpful__thumb-down__selected': _vm.isDownSelected },
        {
          'helpful__thumb-down__clickable': !(_vm.isUpSelected || _vm.isDownSelected),
        },
        'helpful__thumb-down' ],attrs:{"aria-hidden":"true","icon":"thumbsUp","size":"xs"}}),_vm._v(" "),_c('VfText',{class:[
        { 'helpful__thumb-down-count__selected': _vm.isDownSelected },
        { 'helpful__thumb-down-count--redesign': _vm.isCoreRedesignEnabled },
        'helpful__thumb-down-count' ],attrs:{"aria-hidden":"true"}},[_vm._v("\n      "+_vm._s(_vm.thumbDownCount)+"\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }