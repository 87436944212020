



























































import { MappedProductReviewProperty } from 'packages/api-contract/src/entities';
import { PropType, defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfProCon',
  // TODO: GLOBAL15-61059 remove after redesign core
  inject: ['isCoreRedesignEnabled'],
  props: {
    heading: {
      type: String,
      default: '',
    },
    emptyText: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    items: {
      type: Array as PropType<MappedProductReviewProperty[]>,
      default: () => [],
    },
    reducedListCount: {
      type: Number,
      required: true,
    },
    translations: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showTooltipForKey: '',
      isExpanded: false,
    };
  },
  computed: {
    showToggleButton() {
      return this.items.length > this.reducedListCount;
    },
    itemList() {
      if (!this.isExpanded) return this.items.slice(0, this.reducedListCount);
      return this.items;
    },
  },
  methods: {
    toggleItemList() {
      this.isExpanded = !this.isExpanded;
    },
  },
});
