//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, inject } from '@vue/composition-api';
export default defineComponent({
  name: 'VfTag',
  props: {
    label: {
      type: String,
      default: '',
    },
    counter: {
      type: Number,
      default: 0,
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isFullLineSelect: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    return {
      isCoreRedesignEnabled,
    };
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
  },
});
