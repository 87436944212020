













import { defineComponent } from '@nuxtjs/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { onMounted } from '@vue/composition-api';
import { useReferral } from '@vf/composables';

export default defineComponent({
  layout: 'empty',
  setup() {
    const { root } = useRootInstance();
    const { getAffiliateCookies, isLoading } = useReferral(root);

    const animationOptions = {
      animationData: root.$themeConfig?.lottieLoader?.lottieJson,
    };

    onMounted(getAffiliateCookies);

    return {
      animationOptions,
      isLoading,
    };
  },
});
