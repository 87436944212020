
import { onBeforeMount } from '@vue/composition-api';
import { useI18n, ROUTES, useCart } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  setup() {
    const { root } = useRootInstance();
    const { localePath } = useI18n(root);
    const { getSmartGiftCart } = useCart(root);
    onBeforeMount(async () => {
      const smartGiftTc = root.$route.query?.tc;

      if (smartGiftTc) {
        await getSmartGiftCart(smartGiftTc);
        root.$router.push(localePath(ROUTES.CART()));
      } else {
        root.$router.push(localePath(ROUTES.HOME()));
      }
    });
  },
  render: () => ({}),
});
