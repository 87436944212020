































import { computed, defineComponent, ref } from '@vue/composition-api';
export default defineComponent({
  name: 'VfTagGroup',
  props: {
    translations: {
      type: Object,
      default: () => ({
        more: '',
        less: '',
      }),
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const tagsGroupSize = 5;
    const tagsGroupNumber = ref(1);
    const numberOfPages = Math.ceil(props.tags.length / tagsGroupSize);

    const currentLength = computed(() => tagsGroupNumber.value * tagsGroupSize);

    const visibleTags = computed(() => {
      return props.tags.slice(
        0,
        currentLength.value > props.tags.length
          ? props.tags.length
          : Math.min(currentLength.value, props.tags.length)
      );
    });

    const isMoreVisible = computed(
      () => visibleTags.value.length < props.tags.length
    );

    const handleMore = () => {
      tagsGroupNumber.value =
        tagsGroupNumber.value <= numberOfPages
          ? tagsGroupNumber.value + 1
          : tagsGroupNumber.value;
    };

    const isLessVisible = computed(
      () =>
        props.tags.length > tagsGroupSize &&
        visibleTags.value.length === props.tags.length
    );

    const handleLess = () => {
      tagsGroupNumber.value = 1;
    };

    return {
      tagsGroupNumber,
      numberOfPages,
      visibleTags,
      isMoreVisible,
      handleMore,
      isLessVisible,
      handleLess,
    };
  },
});
