

































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
} from '@vue/composition-api';
import { FavoritesTranslations } from '@vf/api-contract';
export default defineComponent({
  name: 'VfFavoritesActions',
  props: {
    favoritesCounter: {
      type: Number,
      required: true,
    },
    isPublicList: {
      type: Boolean,
      default: false,
    },
    isSettingsSectionVisible: {
      type: Boolean,
      default: false,
    },
    sharedFavoritesLink: {
      type: String,
      default: '',
    },
    translations: {
      type: Object as PropType<FavoritesTranslations>,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const radioValue = ref('');
    const showSettingsSection = ref(false);
    const showNotification = ref(false);
    const selectedViewType = ref('grid');
    const showShareableUrl = computed(
      () => radioValue.value === 'shared' && props.sharedFavoritesLink
    );
    const statusNotificationLabel = ref('');
    const privacyListStatusMessage = computed(() =>
      props.isPublicList
        ? props.translations.favoritesListText.shared
        : props.translations.favoritesListText.private
    );

    const hideOnMobile = computed(() =>
      !showSettingsSection.value ? 'no-mobile' : ''
    );

    const changeSection = () => {
      hideNotification();
      showSettingsSection.value = !showSettingsSection.value;
      emit('hide-favorite-products', !showSettingsSection.value);
    };

    const radioButtonOptions = computed(() => [
      {
        label: props.translations.radioButtonLabels.privateLabel,
        value: 'private',
      },
      {
        label: props.translations.radioButtonLabels.sharedLabel,
        value: 'shared',
      },
    ]);

    const setPrivacy = (value: string) => {
      hideNotification();
      radioValue.value = value;
      if (value === 'shared') {
        emit('set-list-public');
      }
      displayNotification(value);
    };

    const hideNotification = () => {
      showNotification.value = false;
    };

    const displayNotification = (value: string) => {
      statusNotificationLabel.value = props.translations.notifications[value];
      showNotification.value = true;
    };

    const copyToClipboard = () => {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(props.sharedFavoritesLink);
        displayNotification('copied');
      }
    };

    const counterLabel = computed(
      () => `${props.favoritesCounter} ${props.translations.itemsCount}`
    );

    const setViewType = (viewType: 'grid' | 'list') => {
      emit('set-view-type', viewType);
      selectedViewType.value = viewType;
    };

    const getSelectViewTypeClass = (viewType: string) => {
      return selectedViewType.value === viewType ? 'selected' : '';
    };

    const isSelected = (viewType: string) => {
      return `${selectedViewType.value === viewType}`;
    };

    onMounted(() => {
      radioValue.value = props.isPublicList ? 'shared' : 'private';
    });

    const contentPositionLinksColOne = {
      small: 'left',
      medium: 'left',
      large: 'left',
    };

    const contentPositionLinksColTwo = {
      small: 'center',
      medium: 'center',
      large: 'center',
    };

    const contentPositionNotifications = contentPositionLinksColOne; // to make different config for different entities

    const contentPositionActionsColOne = {
      small: 'middle_center',
      medium: 'middle_center',
      large: 'left',
    };

    const contentPositionActionsColTwo = {
      small: 'middle_center',
      medium: 'middle_center',
      large: 'center',
    };

    const contentPositionActionsColThree = {
      small: 'middle_center',
      medium: 'middle_center',
      large: 'right',
    };

    const contentPositionSettings = {
      small: 'middle_left',
      medium: 'middle_left',
      large: 'middle_left',
    };

    return {
      changeSection,
      copyToClipboard,
      counterLabel,
      getSelectViewTypeClass,
      hideNotification,
      hideOnMobile,
      privacyListStatusMessage,
      radioButtonOptions,
      radioValue,
      selectedViewType,
      setPrivacy,
      setViewType,
      showNotification,
      showSettingsSection,
      showShareableUrl,
      statusNotificationLabel,
      contentPositionLinksColOne,
      contentPositionLinksColTwo,
      contentPositionNotifications,
      contentPositionActionsColOne,
      contentPositionActionsColTwo,
      contentPositionActionsColThree,
      contentPositionSettings,
      isSelected,
    };
  },
});
