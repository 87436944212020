


























































import { defineComponent, ref, inject } from '@vue/composition-api';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import useRootInstance from '../../theme/shared/useRootInstance';

export interface DetailProperty {
  key: string;
  label: string;
  type: string;
  value: string[];
}

export default defineComponent({
  name: 'VfReviewCategories',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    translations: {
      type: Object,
      default: () => ({
        moreDetails: '',
        lessDetails: '',
      }),
    },
    reviewProperties: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { root } = useRootInstance();

    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    const headingVerticalModifier =
      root.$themeConfig.reviewCategory?.headingVerticalModifier || 'title-5';
    const showToggleIcon =
      root.$themeConfig.reviewCategory?.showToggleIcon || false;
    const isExpanded = ref(false);
    const toggleDetails = () => {
      isExpanded.value = !isExpanded.value;
    };

    const getReviewProperties = (vertical = false) => {
      const verticalPropertiesConfig = ['pros', 'cons', 'bestuses'];
      return [
        ...props.reviewProperties,
      ].filter((property: Record<string, any>) =>
        vertical
          ? verticalPropertiesConfig.includes(property.key)
          : !verticalPropertiesConfig.includes(property.key)
      );
    };
    return {
      isExpanded,
      toggleDetails,
      headingVerticalModifier,
      showToggleIcon,
      verticalProperties: getReviewProperties(true),
      inlineProperties: getReviewProperties(),
      isCoreRedesignEnabled,
    };
  },
});
